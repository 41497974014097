import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { appData } from '@/data/app-data'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: () => import('@/components/IntroPage.vue')
  },

  {
    path: '/slider',
    name: 'slider',
    component: () => import('@/components/SliderPage.vue')
  },

  {
    path: '/addon7',
    name: 'addon7',
    component: () => import('@/components/AddonPage7.vue')
  },

  {
    path: '/addon9',
    name: 'addon9',
    component: () => import('@/components/AddonPage9.vue')
  },

  {
    path: '/addon19',
    name: 'addon19',
    component: () => import('@/components/AddonPage19.vue')
  },

  {
    path: '/addon21',
    name: 'addon21',
    component: () => import('@/components/AddonPage21.vue')
  },

  {
    path: '/addon31',
    name: 'addon31',
    component: () => import('@/components/AddonPage31.vue')
  },

  {
    path: '/addon33',
    name: 'addon33',
    component: () => import('@/components/AddonPage33.vue')
  },

  {
    path: '/product',
    name: 'product',
    component: () => import('@/components/ProductPage.vue')
  },

  {
    path: '/table',
    name: 'table',
    component: () => import('@/components/TablePage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  if (to.name !== 'home' && to.name !== 'slider' && to.name !== 'table' && appData.selectedProduct === 0) {
    return { name: 'slider' }
  }
})

export default router
