import { ProductInterface } from '@/types'

class AppData {
  public productData: ProductInterface[]
  public selectedProduct: number
  public deformation: boolean

  constructor () {
    this.selectedProduct = 0
    this.deformation = false
    this.productData = [
      {
        index: 1, // OK
        product_id: '28U22',
        product_name: 'WalkOn Flex',
        product_description: [
          'In der Schwungphase unterstützt die <strong class="italic">WalkOn Flex</strong> die Anhebung des Fußes, so dass der Gang wieder sicherer wird und die Stolper- und Sturzgefahr sinkt. Aufgrund des Materials (Glas-Carbonfaser-Gemisch) und der speziellen Konstruktion ist die <strong class="italic">WalkOn Flex</strong> besonders geeignet mehr Flexibilität im Knöchelgelenk und im Knie zu erreichen. Wenn also eine ausreichende Restmuskulatur vorhanden ist, kann die <strong class="italic">WalkOn Flex</strong> insbesondere auch in unebenem Gelände gut unterstützen.',
          'Erhältlich in Kinder- und Erwachsenengrößen.'
        ],
        product_image: 'flex-28U22.png',
        table_data: [
          'Weich, lässt Torsions&shy;bewegung zu',
          'Leichter Einfluss auf die Knieflexion',
          'Leichter Einfluss auf die Vorschwungphase',
          'Kompensiert den Fallfuß und hält den Fuß in der Funktionsstellung'
        ]
      },

      {
        index: 2, // In der App ist 33 nicht enthalten
        product_id: '28U11/23',
        product_name: 'WalkOn Classic Trimable',
        product_description: [
          'In der Schwungphase unterstützt die <strong class="italic">WalkOn Classic/Trimable</strong> die Anhebung des Fußes, so dass der Gang wieder sicherer wird und die Stolper- und Sturzgefahr sinkt.',
          'Die Unterschenkelorthese besteht aus einem modernen Carbonfaser-Prepreg-Material, das sehr leicht ist. Rückseitig von der Achillessehne bis hin zur Wade verläuft eine Carbonfeder. Anwender, die über eine restlich verbleibende Muskulatur verfügen, werden in der Gehbewegung durch die <strong class="italic">WalkOn Classic/Trimable</strong> unterstützt. Beim Zehenabstoß gibt die Orthese vorher gespeicherte Energie frei, so dass das Gehen flüssiger wird. Darüber hinaus wird gleichzeitig das Knöchelgelenk stabilisiert.'
        ],
        product_image: 'classic-triamble-28U11-23.png',
        table_data: [
          'Kontrollierter Fersenauftritt, keine Torsion',
          'Leichte Unterstützung der Knieflexion. Aufnahme von Bewegungsenergie in die Karbonstruktur, leichte Kontrolle der mittleren Standphase',
          'Energierückgabe in der Vorschwungphase unterstützt die Zehenablösung in der initialen Schwungphase kein Knieextensionsmoment, leichte Unterstützung der Vorschwungphase',
          'Kompensiert den Fallfuß und hält den Fuß in der Funktionsstellung'
        ]
      },

      {
        index: 3,
        product_id: '28U34', // TODO
        product_name: 'WalkOn Reaction Lateral',
        product_description: [
          'In der Schwungphase unterstützt die <strong class="italic">WalkOn Reaction Lateral</strong> die Anhebung des Fußes, sodass der Gang wieder sicherer wird und die Stolper- und Sturzgefahr sinkt. Die Fußspitze bleibt nicht mehr so leicht an kleinen Hindernissen oder Unebenheiten am Boden hängen. Darüber hinaus unterstützt die <strong class="italic">WalkOn Reaction Lateral</strong> durch ihre spezielle Konstruktion auch das Kniegelenk. Wenn auch die fußsenkende Muskulatur beeinträchtigt ist, profitiert man zudem von der hohen Energierückgabe der Orthese, die während der Abrollbewegung des Fußes frei wird.'
        ],
        product_image: 'lateral-28U34.png', // TODO
        table_data: [
          'Kontrollierter Fersenauftritt, keine Torsion',
          'Moderate Unterstützung der Knieflexion. Aufnahme von Bewegungsenergie in die Karbonstruktur, moderate Kontrolle der mittleren Standphase',
          'Energierückgabe in der Vorschwungphase unterstützt die Zehenablösung in der initialen Schwungphase <strong>leichtes</strong> Knieextensionsmoment, <strong>moderate</strong> Unterstützung der Vorschwungphase',
          'Kompensiert den Fallfuß und hält den Fuß in der Funktionsstellung'
        ]
      },

      {
        index: 4, // OK
        product_id: '28U24',
        product_name: 'WalkOn Reaction',
        product_description: [
          'Die <strong class="italic">WalkOn Reaction</strong> unterstützt in der Schwungphase die Anhebung des Fußes, so dass der Gang sicherer wird und die Stolper- und Sturzgefahr sinkt. Die Fußspitze bleibt nicht mehr so leicht an kleinen Hindernissen oder Unebenheiten am Boden hängen.',
          'Darüber hinaus unterstützt die <strong class="italic">WalkOn Reaction</strong> durch ihre spezielle Konstruktion auch das Kniegelenk. Wenn auch die fußsenkende Muskulatur beeinträchtigt ist, sorgt die Orthese für eine hohe Energierückgabe, die während der Abrollbewegung des Fußes frei wird.'
        ],
        product_image: 'reaction-28U24.png',
        table_data: [
          'Kontrollierter Fersenauftritt, keine Torsion',
          'Moderate Unterstützung der Knieflexion. Aufnahme von Bewegungsenergie in die Karbonstruktur, moderate Kontrolle der mittleren Standphase',
          'Energierückgabe in der Vorschwungphase unterstützt die Zehenablösung in der initialen Schwungphase <strong>moderates</strong> Knieextensionsmoment, <strong>hohe</strong> Unterstützung der Vorschwungphase',
          'Kompensiert den Fallfuß und hält den Fuß in der Funktionsstellung'
        ]
      },

      {
        index: 5, // TODO
        product_id: '28U25',
        product_name: 'WalkOn Reaction Plus',
        product_description: [
          'In der Schwungphase unterstützt die <strong class="italic">WalkOn Reaction plus Lateral</strong> die Anhebung des Fußes, so dass der Gang wieder sicherer wird und die Stolper- und Sturzgefahr sinkt. Die Fußspitze bleibt nicht mehr so leicht an kleinen Hindernissen oder Unebenheiten am Boden hängen. Darüber hinaus unterstützt die <strong class="italic">WalkOn Reaction plus</strong> ebenso wie die <strong class="italic">WalkOn Reaction</strong> durch ihre spezielle Konstruktion auch das Kniegelenk, bietet jedoch eine noch höhere Steifigkeit. Wenn auch die fußsenkende Muskulatur beeinträchtigt ist, profitiert man zudem von der hohen Energierückgabe der Orthese, die während der Abrollbewegung des Fußes frei wird.'
        ],
        product_image: 'reaction_plus_28U25.png',
        table_data: [
          'Kontrollierter Fersenauftritt, keine Torsion',
          'Moderate Unterstützung der Knieflexion. Aufnahme von Bewegungsenergie in die Karbonstruktur, hohe Kontrolle der mittleren Standphase',
          'Energierückgabe in der Vorschwungphase unterstützt die Zehenablösung in der initialen Schwungphase <strong>hohes</strong> Knieextensionsmoment, <strong>hohe</strong> Unterstützung der Vorschwungphase',
          'Kompensiert den Fallfuß und hält den Fuß in der Funktionsstellung'
        ]
      },

      {
        index: 6, // In der App ist 33 nicht enthalten
        product_id: '28U11/23/33',
        product_name: 'WalkOn/&#8203;-Trimable/&#8203;-Lateral',
        product_description: [
          'In der Schwungphase unterstützt die WalkOn Classic/Trimable die Anhebung des Fußes, sodass der Gang wieder sicherer wird und die Stolper- und Sturzgefahr sinkt.',
          'Die Unterschenkelorthese besteht aus einem modernen Carbonfaser-Prepreg-Material, das sehr leicht ist. Rückseitig von der Achillessehne bis hin zur Wade verläuft eine Carbonfeder. Anwender, die über eine restlich verbleibende Muskulatur verfügen, werden in der Gehbewegung durch die WalkOn Classic/Trimable unterstützt. Beim Zehenabstoß gibt die Orthese vorher gespeicherte Energie frei, so dass das Gehen flüssiger wird. Darüber hinaus wird gleichzeitig das Knöchelgelenk stabilisiert.'
        ],
        product_image: '28U211-23.png',
        table_data: [
          'Kontrollierter Fersenauftritt, keine Torsion',
          'Leichte Unterstützung der Knieflexion. Aufnahme von Bewegungsenergie in die Karbonstruktur, leichte Kontrolle der mittleren Standphase',
          'Energierückgabe in der Vorschwungphase unterstützt die Zehenablösung in der initialen Schwungphase <strong>kein</strong> Knieextensionsmoment, <strong>leichte</strong> Unterstützung der Vorschwungphase',
          'Kompensiert den Fallfuß und hält den Fuß in der Funktionsstellung'
        ]
      },

      {
        index: 7, // TODO
        product_id: '28U33',
        product_name: 'WalkOn Lateral',
        product_description: [
          'In der Schwungphase unterstützt die <strong class="italic">WalkOn Lateral</strong> die Anhebung des Fußes, so dass der Gang wieder sicherer wird und die Stolper- und Sturzgefahr sinkt. Die Fußspitze bleibt nicht mehr so leicht an kleinen Hindernissen oder Unebenheiten am Boden hängen. Darüber hinaus unterstützt die <strong class="italic">WalkOn Lateral</strong> durch ihre spezielle Konstruktion auch das Kniegelenk. Wenn auch die fußsenkende Muskulatur beeinträchtigt ist, profitiert man zudem von der hohen Energierückgabe der Orthese, die während der Abrollbewegung des Fußes frei wird.'
        ],
        product_image: 'lateral-28U33.png',
        table_data: [
        ]
      }
    ]
  }
}

export const appData = new AppData()
